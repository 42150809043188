
import { floor } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

  import VueSlickCarousel from 'vue-slick-carousel'

  @Component

  export default class AsyncCarousel extends Vue {

  /** PROPERTIES */

    @Prop({ default: 0 })
    public initialSlide!: number

    @Prop({ default: 10 })
    public slidesToShow!: number

    @Prop({ default: 10 })
    public totalSlides!: number


    /** PRIVATE PROPERTIES */

    protected index = 0
    protected isNextDisabled = false
    protected isPrevDisabled = false
    protected isVisible = true
    protected settings = {
      arrows: false,
      dots: false,
      focusOnSelect: false,
      infinite: false,
      speed: 500,
      swipe: false
    }


    /** PUBLIC METHODS */

    public goToSlide(slide: number) {
      const carousel = this.$refs.vueCarousel as InstanceType<typeof VueSlickCarousel>
      carousel.goTo(slide)
    }

    /** OBSERVERS  */


    @Watch('initialSlide', { immediate: true })
    protected onInitialSlideChanged(initialSlide: number) {
      this.index = floor(initialSlide / this.slidesToShow)
      this.isPrevDisabled = (initialSlide == 0)
      this.isNextDisabled = (initialSlide + this.slidesToShow >= this.totalSlides)
    }

    @Watch('totalSlides', { immediate: true })
    protected onTotalSlidesChanged(totalSlides: number) {
      this.isNextDisabled = (this.initialSlide + this.slidesToShow >= totalSlides)
      this.isVisible = totalSlides > this.slidesToShow
    }


  /** EVENTS */

    protected onAfterChange(index: number) {
      this.index = index
      this.isPrevDisabled = (index == 0)
      this.isNextDisabled = (index + this.slidesToShow >= this.totalSlides)

      this.$emit('afterChange', index)
    }

    protected onNext() {
      const carousel = this.$refs.vueCarousel as InstanceType<typeof VueSlickCarousel>
      carousel.next()
    }

    protected onPrev() {
      const carousel = this.$refs.vueCarousel as InstanceType<typeof VueSlickCarousel>
      carousel.prev()
    }

  }

