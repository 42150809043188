
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { GenderType } from '@/ts/enums/genderType'
  import { Images } from '@/ts/constants/images'
  import { Media } from '@/ts/models/media'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import AWS, { S3 } from 'aws-sdk'

  @Component

  export default class ImageAsync extends Vue {

    /** PROPERTIES */

    @Prop({ default: GenderType.FEMALE }) 
    public gender!: GenderType

    @Prop()
    public media!: Media


    /** PRIVATE PROPERTIES */

    private s3 = new AWS.S3()
    private status = ViewStatus.NONE


    /** OBSERVERS */

    @Watch('media.file', { immediate: true })
    protected onMediaChanged() {
        this.getThumbnail()
        this.getSrc()    
    }

    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }
    protected get loadingUrl() {
      return this.gender == GenderType.FEMALE ? Images.FEMALE : Images.MALE
    }

    protected get notFoundUrl() {
      return this.gender == GenderType.FEMALE ? Images.FEMALE_NOT_FOUND : Images.MALE_NOT_FOUND
    }

    /** PRIVATE METHODS */

    protected async getThumbnail() {
      if (this.media.hasThumbnail) {
        return
      }
      else {

        this.media.thumbSrc = this.loadingUrl
        try {
          const params = {
            Bucket: process.env.VUE_APP_AWS_BUCKET!,
            Key: this.media.file.replace('-high.jpg', '-thumb.jpg')
          }

          const data = await this.s3.getObject(params).promise()
          if (data.Body != null) {
            this.media.thumbSrc = 'data:image/jpg;base64, ' + data.Body.toString('base64')
           
          }
          else {
            this.media.thumbSrc = this.notFoundUrl
            this.$emit('no-image', this.media)
          }
          this.$emit('changed', this.media)        
        }
        
        catch (error) {
          this.$emit('no-image', this.media) 
          this.media.thumbSrc = this.notFoundUrl
        }
      }
    }

    protected async getSrc() {
      if (this.media.hasImage) {
        return
      }
      if (this.media.file == null) {
        this.media.src = this.notFoundUrl
        return
      }

      this.media.src = this.loadingUrl
      this.status = ViewStatus.IN_PROGRESS
      try {
        const params = {
          Bucket: process.env.VUE_APP_AWS_BUCKET!,
          Key: this.media.file
        }
       
        const data = await this.s3.getObject(params).promise()
        if (data.Body != null) {
          this.media.src = 'data:image/jpg;base64, ' + data.Body.toString('base64')
        }
        else {
          this.media.src = this.notFoundUrl
          this.$emit('no-image', this.media)
        }
       
        this.$emit('changed', this.media)
        this.status = ViewStatus.SUCCEEDED
      }
      catch (error) {
        this.$emit('no-image', this.media)
        this.media.src = this.notFoundUrl
        this.status = ViewStatus.FAILED
      }

    }
  }

