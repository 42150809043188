
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
  import { EventLog } from '@/ts/models/eventLog'
  import { PersonStatusType } from '@/ts/enums/personStatusType'


  import ImageAsync from '@/components/images/ImageAsync.vue'


    @Component({
      components: {
        ImageAsync
      }
    })
    export default class EventSlide extends Vue {

    /** PROPERTIES */

    @Prop({ default: true })
    public clickable!: boolean

    @Prop()
    public event!: EventLog

    @Prop({ default: false })
    public selectable!: boolean

    @Prop({ default: false })
    public selected!: boolean


    /** PRIVATE PROPERTIES */

    protected isSelected = false


    @Watch('selected', { immediate: true })
    protected onSelectedChanged(selected: boolean) {
      this.isSelected = selected
    }

    /** COMPUTED PROPERTIES */

      protected get initial() {
        if (this.event.patron?.person?.givenNames == null) {
          return ''
        }

        let initials = ''
        const givenNames = this.event.patron.person.givenNames.split(' ')
        for (const name of givenNames) {
          initials += name.substr(0, 1)
          initials += '. '
        }

        return initials
      }

      protected get isAssociatedEvent() {
      return this.event.timeFrameId != this.event.id
    }

    protected get backgroundColor() {
      return (this.event.isReEntry) ? 'bg-warning' : 'bg-white'
    }

      protected get statusColor() {
        const person = this.event.patron?.person
        if (person == null) {
          return 'bg-white'
        }

        if (this.event.patron?.isBanned) {
          return 'bg-danger'
        }

        const status = person.personStatus.id as PersonStatusType
        switch (status) {
          case PersonStatusType.NEW:
            return 'bg-primary'

          case PersonStatusType.IDQUERY:
            return 'bg-info'

          case PersonStatusType.VERIFIED:
            return 'bg-success'

          default:
            return 'bg-white'
        }
      }


    /** EVENTS */

    protected onChange(selected: boolean) {
      this.$emit('change', selected, this.event)
    }

    protected onClick() {
      this.$emit('click', this.event)
    }


    /** PRIVATE METHODS */

    protected formatDate(date: Date) {
      return DateFormatter.format(date, DateStyle.MediumTime)
    }
  }
