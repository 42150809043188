
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { Match } from '@/ts/models/match'
  import { Media } from '@/ts/models/media'
  import { Person } from '@/ts/models/person'
  import { PersonStatusType } from '@/ts/enums/personStatusType'

  import ImageAsync from '@/components/images/ImageAsync.vue'


  @Component({
    components: {
      ImageAsync
    }
  })
  export default class PersonSlide extends Vue {

    /** PROPERTIES */

    @Prop({ default: true })
    public clickable!: boolean

    @Prop({ default: false })
    public highlighted!: boolean

    @Prop({ default: null })
    public match!: Match

    @Prop()
    public person!: Person

    @Prop({ default: true })
    public selectable!: boolean

    @Prop({ default: false })
    public selected!: boolean


    /** PRIVATE PROPERTIES */

    protected isSelected = false


    /** OBSERVERS */

    @Watch('selected', { immediate: true })
    protected onSelectedChanged(selected: boolean) {
      this.isSelected = selected
    }

    /** COMPUTED PROPERTIES */

    protected get backgroundColor() {

      if (this.person.isBanned) {
        return 'bg-danger'
      }

      const status = this.person.personStatus.id as PersonStatusType
      switch (status) {
        case PersonStatusType.NEW:
          return 'bg-primary'

        case PersonStatusType.IDQUERY:
          return 'bg-info'

        case PersonStatusType.VERIFIED:
          return 'bg-success'

        default:
          return 'bg-white'
      }
    }

    protected get gender() {
      return this.person.gender.name.toLowerCase()
    }

    protected get initial() {
      if (this.person.givenNames == null) {
        return ''
      }

      let initials = ''
      const givenNames = this.person.givenNames.split(' ')
      for (const name of givenNames) {
        initials += name.substr(0, 1)
        initials += '. '
      }

      return initials
    }

    protected get isBanned() {
      return this.person.isBanned
    }

    
    /** EVENTS */

    protected onChange(selected: boolean) {
      this.$emit('change', selected, this.person)
    }

    protected onClick() {
      this.$emit('click', this.person)
    }

    protected onMediaUpdated(media: Media) {
      this.person.proofOfId.photo = media
      this.$emit('update', this.person)
    }
  }

