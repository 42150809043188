
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { PersonStatusType } from '@/ts/enums/personStatusType'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class StatusKey extends Vue {

    /** PROPERTIES */

    @Prop({ default: false })
    public isBanned!: boolean

    @Prop({ default: false })
    public isReEntry!: boolean

    @Prop({ default: false })
    public isTagged!: boolean

    @Prop({ default: PersonStatusType.NONE })
    public personStatusType!: PersonStatusType

    @Prop({ default: true })
    public showVerified!: boolean

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus


    /** PRIVATE PROPERTIES     */

    protected isVisible = this.personStatusType != PersonStatusType.NONE
    protected variant = 'white'


    /** OBSERVERS */

    @Watch('personStatusType', { immediate: true })
    protected onPersonStatusChanged(statusType: PersonStatusType) {
      switch (statusType) {
        case PersonStatusType.IDQUERY:
          this.variant = 'info'
          break
        case PersonStatusType.NEW:
          this.variant = 'primary'
          break
        case PersonStatusType.VERIFIED:
          this.variant = 'success'
          this.isVisible = this.showVerified
          break
      }
    }


    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }
  }
