import { render, staticRenderFns } from "./PersonSlide.vue?vue&type=template&id=54a0b4ce&scoped=true&"
import script from "./PersonSlide.vue?vue&type=script&lang=ts&"
export * from "./PersonSlide.vue?vue&type=script&lang=ts&"
import style0 from "./PersonSlide.vue?vue&type=style&index=0&id=54a0b4ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a0b4ce",
  null
  
)

export default component.exports